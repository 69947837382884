.sdc-article-header__wrap {
  .sdc-site-component-header--h1 {
    font-size: 48px !important;
    margin-bottom: 22px !important;
    line-height: 56px;
  }

  .sdc-site-component-header--h2 {
    font-size: 22px !important;
    margin-bottom: 46px !important;
    line-height: 30px;
  }

  @media all and (max-width: 600px) {
    .sdc-site-component-header--h1 {
      font-size: 32px !important;
      margin-bottom: 16px !important;
      line-height: 40px;
    }

    .sdc-site-component-header--h2 {
      font-size: 20px !important;
      margin-bottom: 24px !important;
      line-height: 26px;
    }
  }
}
