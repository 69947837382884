$sdc-article-related-stories-link-colour: var(--link-color);
$sdc-article-related-stories-title-font-size: 18px;

.sdc-article-related-stories {
  .sdc-article-related-stories__content {
    border: 1px solid var(--border-color);
    border-radius: 5px;
  }

  .sdc-article-related-stories__title {
    font-family: var(--branding-sky-headline-font);
    font-size: var(--display-3);
    line-height: 1;
    text-transform: uppercase;
    text-align: left;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
  
  .sdc-article-related-stories__title::before {
    content: "";
    width: 8px;
    height: 26px;
    background: var(--brand-3);
    margin-right: 0.5em;
  }
}
