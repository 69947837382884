.sdc-site-feature-tile {
  position: relative;
}

.sdc-site-feature-tile__inner {
  overflow: hidden;
}

.sdc-site-feature-tile__image {
  width: 100%;
  transition: transform 0.5s;
  will-change: transform;
}

.sdc-site-feature-tile:hover .sdc-site-feature-tile__image {
  transform: scale(1.1);
}

.sdc-site-feature-tile__body {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background: black;
  background: linear-gradient(0deg, rgba(0, 0, 0, .99) 27.5%, transparent);
  color: white;
  padding: .9em .9em 1.15em;
  font-size: 1.55em;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .7);
}

.sdc-site-feature-tile__headline {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: .3em;
  line-height: 1;
}

.sdc-site-feature-tile__headline-link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 200%;
  left: 0;
  top: -100%;
}

.sdc-site-feature-tile__snippet {
  font-size: 1rem;
}

@media all and (max-width: 420px) {

  .sdc-site-feature-tile__body {
    font-size: 1.25em;
  }

  .sdc-site-feature-tile__snippet {
    font-size: .95rem;
  }
}