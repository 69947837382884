.ui-climate-counter__embed--get-time-until,
.ui-climate-counter__embed--total-emissions,
.ui-climate-counter__embed--human-induced-warming {
    height: calc(105px + 20vw);
    max-height: 235px;
    width: 100%;
}

.ui-climate-counter__embed--energy-mix {
    height: calc(250px + 34vw);
    max-height: 500px;
    min-height: 350px;
    width: 100%;
}
