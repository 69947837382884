/* line 18, cop 26 etc */
.video-hero-index {
  .sdc-site-tile__significance {
    display: none;
  }

  .sdc-site-hero {
    @extend .site-component-vertical-margin-negative !optional;
  }

  .sdc-site-hero__description {
    background: #000001;
    color: #E2E1DF;
    font-size: 18px;
    line-height: 1.4;
    padding: 1.9em 0;
    text-align: center;
    margin-bottom: 1.6em;
  }

  .sdc-site-hero__description--top p {
    margin-bottom: 0;
  }

  .sdc-site-hero__description--bottom {
    background: none;
    color: inherit;
    padding-top: 0;
    margin-top: -10px;
  }

  .sdc-site-hero__description--bottom p {
    max-width: 700px;
    margin: 0 auto 1.5em;
    line-height: 1.5
  }

  @media all and (min-width: 768px){
    .sdc-site-hero__description--top {
      padding: 1.1em 0;
      font-size: 26px;
    }
  }

  @media all and (max-width: 480px){
    .sdc-site-hero__description {
      padding: 0.94em 0 1em;
      margin-bottom: 0;
      line-height: 1.2
    }
  }
}

.video-hero-index--new-lines {
  .sdc-site-hero__description {
    background: #3393FF;
    color: #ffffff;
  }

  .sdc-site-hero, .sdc-site-hero__media {
    min-height: 0;
    max-height: none;
  }
  @supports (object-fit: cover) {
    .sdc-site-hero {
      height: 35vh;
      min-height: 260px;
    }

    @media all and (max-width: 600px) {
      .sdc-site-hero {
        height: 35vh;
        min-height: 200px;
      }
    }

    @media all and (max-width: 1685px) {
      .sdc-site-hero {
        height: 26vh;
      }
    }
  }
  .sdc-site-au[data-ad-format="mpu-1"] {
    width: 100%;
  }

  .sdc-site-tiles--alt2 {
    .sdc-site-tiles__item:nth-child(5n+4), .sdc-site-tiles__item:nth-child(5n+5) {
      width: 100%;
    }
  }
}

