/* Override font file paths */
@import "./fonts.scss";

/* import common core file, each site or site section css file should start with this */
@import "../../../node_modules/ui-project-base/css/base/core/core";

/* add news specific css vars */
@import "../../../node_modules/ui-project-base/css/base/core/vars/_vars-uk-news";

/* sdc project css
 - CSS shared by all SDC projects */
@import "../../../node_modules/ui-project-base/css/base/sdc-skin/palette";
@import "../../../node_modules/ui-project-base/css/base/sdc-skin/settings";
@import "../../../node_modules/ui-project-base/css/base/sdc-skin/site-layout";
@import "../../../node_modules/ui-project-base/css/base/sdc-skin/glints";
@import "../../../node_modules/ui-project-base/css/base/sdc-skin/ui";
@import "../../../node_modules/ui-project-base/css/base/sdc-skin/utilities";

/* Sky News project css - CSS specific to the Sky News project */
@import "../news-skin/*.scss";

/* skin files for imported blackjack components */
@import "../../../node_modules/ui-project-base/css/base/component-skin/global-mobile";
@import "../../../node_modules/ui-project-base/css/base/component-skin/global";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-article-body";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-article-header";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-article-image";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-article-related-stories";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-site-component-header";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-site-countdown-timer";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-site-error-page";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-site-hero";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-site-load-more";
@import "../../../node_modules/ui-project-base/css/base/component-skin/sdc-site-localnav";

/* project one overrides/settings */
@import "../component/vislang-legacy.scss";

@import "../../../node_modules/ui-project-base/css/base/v2/core/vars";
@import "../../../node_modules/ui-project-base/css/base/v2/core/wrap";
@import "../../../node_modules/ui-project-base/css/base/v2/core/grids";
@import "../../../node_modules/ui-project-base/css/base/v2/core/gaps";
@import '../../../node_modules/ui-project-base/css/base/v2/core/typeography';
@import "../../../node_modules/ui-project-base/css/base/v2/utils/aspect-ratio";
@import '../../../node_modules/ui-project-base/css/base/v2/ui/box';
@import "../../../node_modules/ui-project-base/css/base/v2/ui/scrollbars";
@import "../../../app/Resources/views/local-components/ui-label/styles";
@import "../../../node_modules/ui-project-base/css/base/v2/ui/lists";
@import "../../../node_modules/ui-project-base/css/base/v2/ui/media-caption";
@import "../../../node_modules/ui-project-base/css/base/v2/ui/no-js";

:root {
    color-scheme: light dark;
}

iframe {
    color-scheme: normal;
}

.content {
    display: flow-root;
}