.sdc-site-au--full-bleed + .sdc-site-hero, .sdc-site-au--sponsor-position-container + .sdc-site-hero, .sdc-site-au--full-bleed + .sdc-site-countdown-timer {
    margin-top: calc(var(--component-margin) * -1);
  }
  
  .sdc-site-au-wrapper--visibility {
    display: none !important;
  }
  
  .sdc-site-layout-sticky-region {
    .sdc-site-au-wrapper--visibility {
      display: block !important;
    }
  }
  
  .leaderboard-spacer {
    margin-top: var(--component-margin);
  }
  
  .leaderboard-spacer ~ .sdc-site-countdown-timer {
    margin-top: calc(var(--component-margin) * -1);
  }
  
  /**
   * Hides the sticky MPU when a user has rejected consent for Google Tag manager or Peer 39 to prevent empty space above the
   * sidebar trending widget on article pages. This solution won't work in Firefox as the :has selector is currently unsupported.
   */
  html:has(head > [data-vendor-name="google-tag-manager"][data-consent="false"]) .sdc-site-layout-sticky-region__content .sdc-site-au-wrapper,
  .sdc-site-layout-sticky-region:has([data-vendor-name="peer-39"][data-consent="false"]) .sdc-site-layout-sticky-region__content .sdc-site-au-wrapper {
    display: none !important;
  }
  
  @media all and (max-width: 939px) {
    .sdc-site-layout-sticky-region {
      &--visibility {
        display: none !important;
      }
    }
  }
  
  /* DEFENSIVE CODE AGAINST STRAY AD CSS INJECTION */
  .sdc-site-component-header--h2.sdc-site-component-header--project-one[class][class][class] {
    background: none !important;
    padding-left: var(--site-gutter);
    padding-right: var(--site-gutter);
  }
  
  // .sdc-site-au--full-bleed[class][class][class] {
  //   padding-bottom: 0 !important;
  // }
  
  #inskinanchor {
    margin-bottom: 0 !important;
  }