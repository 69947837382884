.sdc-counter-iframe-wrap {
  position: relative;
  height: 120px;
  & > a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
  & > iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}

.sdc-vaccine-counter {
  padding-top: 2%;
  height: 120px;
  color: var(--text-color);
  font-weight: bolder;
  text-align: center;
  font-size: 1.2rem;
  background-image: url("/assets/img/vaccine_bg_628x120.png?bypass-service-worker");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
}

#sdc-vaccine-counter__number {
  font-size: 2.3em;
  line-height: 1.3em;
}