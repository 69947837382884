.sdc-site-hero {
  --site-hero-text-highlight: #FECB3C;
}

// Base COP26 banner styles
.sdc-site-hero--cop26 {
  .sdc-site-hero__inner {
    align-items: center;
  }

  .sdc-site-hero__body {
    padding: 0;
  }

  .sdc-site-hero__snippet {
    font-family: var(--branding-sky-headline-font);
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1;

    span {
      color: var(--site-hero-text-highlight);
    }
  }

  .sdc-site-hero__links {
    svg {
      fill: none;
    }
  }
}

@media (max-width: 600px) {
  .sdc-site-hero--cop26 {
    .sdc-site-hero__inner[class] {
      padding-bottom: 0;
    }

    .sdc-site-hero__snippet[class] {
      display: block;
    }

    .sdc-site-hero__links {
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}

// COP 26 index page banner overrides
.custom-index {
  .sdc-site-hero--cop26 {
    max-height: 100px;
    min-height: 100px;
    background: transparent url("https://components.news.sky.com/files/cop26-index-poster-desktop.png?bypass-service-worker") 50% 50% / cover no-repeat;
    border-bottom: 3px solid var(--site-hero-text-highlight);

    .sdc-site-hero__snippet {
      font-size: calc(var(--display-2) + 12px);
    }

    .sdc-site-hero__media {
      max-height: 100px;
      min-height: 100px;
    }
    
    @media (prefers-reduced-motion) {
      .sdc-site-hero__media {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    .sdc-site-hero--cop26 {
      max-height: 50px;
      min-height: 50px;
      background: transparent url("https://components.news.sky.com/files/cop26-index-poster-mobile.png?bypass-service-worker") 50% 50% / cover no-repeat;

      .sdc-site-hero__snippet[class] {
        font-size: calc(var(--display-2) + 4px);
      }
  
      .sdc-site-hero__media {
        max-height: 50px;
        min-height: 50px;      }
    }
  }
}
