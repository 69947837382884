.sdc-article-custom-markup {
  .instagram-media,
  .instagram-media-registered {
    margin: 0 auto !important;
    right: 0;
    left: 0;
  }
}

/* tune in radio player widget */
.sdc-article-custom-markup[data-vendor-name="tune-in"] iframe {
  height: 102px;
}

.sdc-article-custom-markup[data-vendor-name="facebook"] .sdc-article-custom-markup__inner {
  justify-content: center;
  display: flex;
}

