/* Add hacks to update legacy code with new design */

/* vislang ui-labels */
:root body {
  --ui-label-size-s: 12px;
  --ui-label-size-m: 12px;
  --ui-label-size-l: 14px;
  --ui-label-size-xl: 18px;
  --utility-live: #e90e10;
  --utility-breaking: #f8eb1f;
  --utility-story: #032c77;
}

@media all and (prefers-color-scheme: dark) {
  :root body {
    --utility-story: #164c99;
  }
}

.sdc-article-header__titles .ui-label {
  margin-bottom: 0.2em;
}

.sdc-site-component-header--project-one[class] {
  font-family: var(--branding-sky-headline-font);
  font-size: calc(var(--display-2) + 4px) !important;
  text-transform: uppercase;
  line-height: 1;
}

/* Breaking news on site tile
  - Adds yellow gradient background when top story is breaking news 
  - inverts breaking news label
*/
.sdc-site-tile--breaking[data-type~=hero-horizontal],
.sdc-site-tile--breaking--live[data-type~=hero-horizontal] {
  --link-color: var(--breaking-color);
  --text-color: var(--breaking-color);
  background-image: var(--breaking-bg) !important;
  color: var(--breaking-color);
  background-clip: content-box;
}

.sdc-site-tile--breaking[data-type~=hero-horizontal] .ui-label[data-type~="breaking"],
.sdc-site-tile--breaking--live[data-type~=hero-horizontal] .ui-label[data-type~="breaking"] {
  background: var(--breaking-color) !important;
  color: var(--breaking) !important;
}

/* watch-live header
- adds new font and adjusts spacing
*/
.sdc-site-component-header--project-one[class].sdc-site-component-header--h1 {
  font-size: var(--display-1) !important;
}

/* article topic tags
 - increase font size to 14
 - Use sentence case
 - hide from article header in regular articles - now bottom left
 */
 .sdc-article-tags__link {
  font-size: 14px;
  text-transform: capitalize;
}
