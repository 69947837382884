.full-page-embed {
  height: 100%;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.full-page-embed main {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.full-page-embed .section-wrap {
  background: 0 !important;
}

.full-page-embed .section-wrap .ui-consent-roadblock[class] {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0;
}

.full-page-embed .sdc-article-widget {
  margin-bottom: 0 !important;
}

.full-page-embed main iframe {
  position: absolute;
  width: 100%;
  height: 100% !important;
  left: 0;
  top: 0;
  border: 0;
}

.full-page-embed footer {
  display: none;
}