.sdc-trust-project {
  font-size: var(--component-text);
  padding-bottom: var(--component-margin);
}

.sdc-trust-project__link {
  display: inline-flex;
  align-items: center;
  color: var(--text-color-secondary);
}

.sdc-trust-project__link > svg {
  margin-right: 7px;
}

.sdc-trust-project__arrow-icon {
  @include sdc-ui-link-arrow()
}