.sdc-article-header--story-article .sdc-article-header__wrap[class],
.sdc-story-article-author .sdc-article-author[class]{
  max-width: 976px;
}

.section-wrap .sdc-story-article-component-header[class] {
  @extend .site-wrap-padding !optional;
  max-width: 1024px;
}

.sdc-story-article-author[class] > .sdc-article-author {
  padding-top: 0;
  margin-bottom: 30px;
}

.sdc-article-header--story-article .sdc-site-component-header--h1,
.sdc-article-header--story-article .sdc-site-component-header--h2 {
  max-width: 800px;
}

.sdc-article-image__caption-text[class][class] {
  max-width: 100% !important;
}

.sdc-site-layout-sticky-region {
  pointer-events: none;
}

.sdc-site-layout-sticky-region .sdc-site-au-wrapper {
  pointer-events: all;
}

@media all and (min-width: 940px){
  .sdc-site-layout
  .sdc-article-quote--with-image[class] {
    margin-left: 24px;
    margin-right: 24px;
  }
}
