/* set the width of the video embeds and the tune in widget on watch live and videos in articles */
.watch-live .sdc-article-widget[class][class],
.sdc-site-video:not(.sdc-article-widget--full-bleed),
.sdc-article-gallery:not(.sdc-article-widget--full-bleed),
.sdc-article-image:not(.sdc-article-image--full-bleed) {
  max-width: var(--site-width-inner) !important;
  margin-left: auto;
  margin-right: auto;
}

/* fixes accident scrollbars appearring in iframe videos */
.sdc-site-embedded .sdc-site-video {
  overflow: hidden;
}

// Disable full screen ads on iPad
.is-ipad .vjs-ad-control-bar .vjs-fullscreen-control {
  display: none;
}
  